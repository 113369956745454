import request from "@/utils/request";

// Warehouse
export function warehouseList(params) {
  return request({ url: `/warehouses/`, method: "get", params });
}

export function warehouseCreate(data) {
  return request({ url: `/warehouses/`, method: "post", data });
}

export function warehouseUpdate(data) {
  return request({ url: `/warehouses/${data.id}/`, method: "put", data });
}

export function warehouseDestroy(data) {
  return request({ url: `/warehouses/${data.id}/`, method: "delete", data });
}

export function warehouseNumber(params) {
  return request({ url: `/warehouses/number/`, method: "get", params });
}

export function warehouseTemplate(params) {
  return request({
    url: "/warehouses/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function warehouseImport(data) {
  return request({
    url: "/warehouses/import_data/",
    method: "post",
    data,
  });
}

export function warehouseExport(params) {
  return request({
    url: "/warehouses/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// Area
export function areaList(params) {
  return request({ url: `/areas/`, method: "get", params });
}

export function areaCreate(data) {
  return request({ url: `/areas/`, method: "post", data });
}

export function areaUpdate(data) {
  return request({ url: `/areas/${data.id}/`, method: "put", data });
}

export function areaDestroy(data) {
  return request({ url: `/areas/${data.id}/`, method: "delete", data });
}

export function areaNumber(params) {
  return request({ url: `/areas/number/`, method: "get", params });
}

export function areaTemplate(params) {
  return request({
    url: "/areas/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function areaImport(data) {
  return request({
    url: "/areas/import_data/",
    method: "post",
    data,
  });
}

export function areasExport(params) {
  return request({
    url: "/areas/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// Location
export function locationList(params) {
  return request({ url: `/locations/`, method: "get", params });
}

export function locationCreate(data) {
  return request({ url: `/locations/`, method: "post", data });
}

export function locationUpdate(data) {
  return request({ url: `/locations/${data.id}/`, method: "put", data });
}

export function locationDestroy(data) {
  return request({ url: `/locations/${data.id}/`, method: "delete", data });
}

export function locationNumber(params) {
  return request({ url: `/locations/number/`, method: "get", params });
}

export function locationsTemplate(params) {
  return request({
    url: "/locations/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function locationsImport(data) {
  return request({
    url: "/locations/import_data/",
    method: "post",
    data,
  });
}

export function locationsExport(params) {
  return request({
    url: "/locations/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// Client
export function clientList(params) {
  return request({ url: `/clients/`, method: "get", params });
}

export function clientCreate(data) {
  return request({ url: `/clients/`, method: "post", data });
}

export function clientUpdate(data) {
  return request({ url: `/clients/${data.id}/`, method: "put", data });
}

export function clientDestroy(data) {
  return request({ url: `/clients/${data.id}/`, method: "delete", data });
}

export function clientNumber(params) {
  return request({ url: `/clients/number/`, method: "get", params });
}

export function clientTemplate(params) {
  return request({
    url: "/clients/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function clientImport(data) {
  return request({
    url: "/clients/import_data/",
    method: "post",
    data,
  });
}

export function clientExport(params) {
  return request({
    url: "/clients/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// Category
export function categoryList(params) {
  return request({ url: `/categories/`, method: "get", params });
}

export function categoryCreate(data) {
  return request({ url: `/categories/`, method: "post", data });
}

export function categoryUpdate(data) {
  return request({ url: `/categories/${data.id}/`, method: "put", data });
}

export function categoryDestroy(data) {
  return request({ url: `/categories/${data.id}/`, method: "delete", data });
}

export function categoryTemplate(params) {
  return request({
    url: "/categories/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function categoryImport(data) {
  return request({
    url: "/categories/import_data/",
    method: "post",
    data,
  });
}

export function categoryExport(params) {
  return request({
    url: "/categories/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}
